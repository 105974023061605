<template>
  <div>
    <div class="landing-page">
      <div class="landing-page__banner">
        <img
          src="./asset/banner.jpg"
          alt="Banner Stucel"
          class="landing-page__banner-img"
        >
        <div class="landing-page__banner-text">
          <h2 class="mb-3">
            <strong>Stucel</strong>
          </h2>
          <h4 class="mb-1">
            <strong>Digital marketing agency, that suits you</strong>
          </h4>
          <p>We provide strategies to achieve a better online presence, reach more customers, better conversion, and a greater return on your investment. Transparency, ethics, and results always come first.</p>
        </div>
      </div>
      <div class="landing-page__project">
        <div class="landing-page__project-title">
          <h4><strong style="color: white;">Work that works</strong></h4>
        </div>
        <div class="landing-page__project-slider">
          <VueSlickCarousel v-bind="settings">
            <div
              v-for="(project, index) in projects"
              :key="index"
              class="landing-page-image text-center"
            >
              <img
                :src="getProjectImgUrl(index+1)"
                alt="Project"
                class="project-img"
              >
              <a :href="project.url">{{ project.label }}</a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <div class="landing-page__form-solution">
        <b-row class="row-content">
          <b-col
            md="6"
          >
            <div class="landing-page__form">
              <div class="auth-v1 w-100">
                <b-card class="h-100">
                  <h4 class="mb-3">
                    <strong>{{ formData.title }}</strong>
                  </h4>
                  <validation-observer
                    v-if="!formSucceded"
                    ref="formCustomLP"
                    #default="{invalid}"
                  >
                    <b-form
                      class="landing-page-form mt-2"
                      @submit.prevent="onSubmitForm"
                    >
                      <div
                        v-for="(item, index) in formData.labels"
                        :key="index"
                        ref="row"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="item"
                          :rules="formData.required[index] !== undefined && formData.required[index] == true? 'required' : ''"
                        >
                          <b-form-group
                            v-if="formData.types[index] === 'select'"
                            :label="item"
                            :label-for="formData.names[index]"
                          >
                            <b-form-select
                              v-model="formStore[formData.names[index]]"
                              :options="formData.options[index]"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>

                          <b-form-group
                            v-else-if="formData.types[index] === 'textarea'"
                            :label="item"
                            :label-for="formData.names[index]"
                          >
                            <b-form-textarea
                              :id="formData.names[index]"
                              v-model="formStore[formData.names[index]]"
                              :state="errors.length > 0 ? false:null"
                              trim
                              rows="2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>

                          <b-form-group
                            v-else-if="formData.types[index] === 'checkbox'"
                            :label="item"
                            :label-for="formData.names[index]"
                          >
                            <b-form-checkbox-group
                              :id="formData.names[index]"
                              v-model="formStore[formData.names[index]]"
                              :state="errors.length > 0 ? false:null"
                              :name="formData.names[index]"
                              :options="formData.options[index]"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>

                          <b-form-group
                            v-else-if="formData.types[index] === 'radio'"
                            :label="item"
                            :label-for="formData.names[index]"
                          >
                            <b-form-radio-group
                              :id="formData.names[index]"
                              v-model="formStore[formData.names[index]]"
                              :options="formData.options[index]"
                              :state="errors.length > 0 ? false:null"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>

                          <b-form-group
                            v-else-if="formData.types[index] === 'date'"
                            :label="item"
                            :label-for="formData.names[index]"
                          >
                            <flat-pickr
                              :id="formData.names[index]"
                              v-model="formStore[formData.names[index]]"
                              class="form-control"
                              :options="formData.options[index]"
                              :config="{ dateFormat: 'd-m-Y'}"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>

                          <b-form-group
                            v-else
                            :label="item"
                            :label-for="formData.names[index]"
                          >
                            <b-form-input
                              :id="formData.names[index]"
                              v-model="formStore[formData.names[index]]"
                              :type="formData.types[index]"
                              :state="errors.length > 0 ? false:null"
                              :name="formData.names[index]"
                              placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                      <!-- submit button -->
                      <div>
                        <b-button
                          class="mt-2"
                          type="submit"
                          :disabled="invalid"
                          variant="primary"
                          style="width: fit-content"
                          @click="recaptcha"
                        >
                          <span style="color: white;">Submit</span>
                        </b-button>
                      </div>
                    </b-form>
                  </validation-observer>
                </b-card>
              </div>
            </div>
          </b-col>
          <b-col
            md="6"
          >
            <div class="landing-page__solution" style="padding: 1.5rem;">
              <h4 class="mb-3">
                <strong>Our digital marketing solutions</strong>
              </h4>
              <div>
                <b-row v-for="(solution, index) in solutions" :key="index">
                  <b-col md="2">
                    <img :src="getSolutionImgUrl(index+1)">
                  </b-col>
                  <b-col md="10">
                    <h5>
                      <strong>{{ solution.title }}</strong>
                    </h5>
                    <p>{{ solution.description }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="landing-page__reason">
        <h4 class="mb-3 mt-5 text-center">
          <strong>Why Us?</strong>
        </h4>
        <b-row>
          <b-col
            v-for="(reason, index) in reasons"
            :key="index"
            md="4"
          >
            <div class="landing-page__reason-img">
              <img :src="getReasonImgUrl(index+1)">
            </div>
            <div class="landing-page__reason-content">
              <h5 class="mb-2 mt-1">
                <strong>{{ reason.title }}</strong>
              </h5>
              <p>{{ reason.description }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="landing-page__testimony">
        <h4 class="mt-5 text-center">
          <strong>What They Say About Us</strong>
        </h4>
        <b-carousel
          id="landing-page__testimony"
          v-model="slide"
          :interval="3000"
          indicators
          background="transparant"
          img-height="300"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            v-for="(testimony, index) in testimonies"
            :key="index"
            img-blank
          >
            <span>
              {{ testimony.testimony }}
              <span>
                <p class="mt-2 mb-0"><strong>{{ testimony.name }}</strong></p>
                <p>{{ testimony.company }}</p>
              </span>
            </span>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="landing-page__client">
        <h4 class="mb-3 mt-5 text-center">
          <strong>Our Clinets</strong>
        </h4>
        <VueSlickCarousel v-bind="settingClient">
          <div
            v-for="item in 8"
            :key="item"
            class="landing-page__client-img"
          >
            <img
              :src="getClientImgUrl(item)"
              alt="Client"
              class="client-img"
            >
        </div>
        </VueSlickCarousel>
      </div>
      <div class="landing-page__faq">
        <h4 class="mb-3 mt-5">
          <strong>FAQ</strong>
        </h4>
        <AppCollapse>
          <AppCollapseItem
            v-for="item in faq"
            :key="item.id"
            :title="item.question"
          >
            {{ item.answer }}
          </AppCollapseItem>
        </AppCollapse>
      </div>
    </div>
    <div class="footer mt-5">
      <div class="footer__content">
        <b-row>
          <b-col
            md="4"
            sm="12"
          >
            <div class="footer__content-links">
              <p class="mb-1">Other Links</p>
              <div class="d-flex flex-column footer-btn">
                <b-button
                  variant="outline-primary"
                  pill
                  target="_blank"
                  size="sm"
                  href="https://google.com/"
                >
                  Stucel.com
                </b-button>
                <b-button
                  variant="outline-primary"
                  pill
                  target="_blank"
                  size="sm"
                  href="https://google.com/"
                >
                  WhatsApp
                </b-button>
                <b-button
                  variant="outline-primary"
                  pill
                  target="_blank"
                  size="sm"
                  href="https://google.com/"
                >
                  Get Directions in Google Map
                </b-button>
            </div>
            </div>
          </b-col>
          <b-col
            md="4"
            sm="12"
          >
            <div class="footer__content-sosmed">
              <p class="mb-1">We're available at</p>
              <b-link
                target="_blank"
                href="https://google.com/"
                class="py-1 pr-1"
              >
                <i class="bi bi-instagram" />
              </b-link>
              <b-link
                target="_blank"
                href="https://google.com/"
                class="p-1"
              >
                <i class="bi bi-facebook" />
              </b-link>
              <b-link
                target="_blank"
                href="https://google.com/"
                class="p-1"
              >
                <i class="bi bi-linkedin" />
              </b-link>
            </div>
          </b-col>
          <b-col
            md="4"
            sm="12"
            class="footer__content-address"
          >
            <span>
              <p><strong>PT Stucel Media Kreatif</strong></p>
              <p>
                Rukan Gading SOHO blok GSOC No. 8,
                Sedayu City, Kelapa Gading.
                Cakung, Jakarta 13920
              </p>
            </span>
            <div class="mt-1">
              <span class="d-flex" style="height: 22px">
                <i class="bi bi-whatsapp pr-1" />
                <p>+62-878-09882-789</p>
              </span>
              <span class="d-flex">
                <i class="bi bi-envelope pr-1" />
                <a href="mailto:hello@stucel.com">
                  hello@stucel.com
                </a>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
          >
            <div class="text-center mt-1">
              <p style="margin-bottom: 5px;">
                Powered by
              </p>
              <b-link
                class="brand-logo"
                to="/"
              >
                <img
                  :src="require('@/assets/images/logo/TraceBit-logo.svg')"
                  alt="Logo"
                  height="25"
                >
              </b-link>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCarousel, BCarouselSlide, BButton,
  BLink, BForm, BFormGroup, BFormTextarea, BFormSelect,
  BFormInput, BFormRadioGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import store from '@/store'
import VueSlickCarousel from 'vue-slick-carousel'
import { required, email } from '@validations'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BRow,
    BCol,
    VueSlickCarousel,
    BCard,
    BCarousel,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BCarouselSlide,
    AppCollapse,
    AppCollapseItem,
    BButton,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      settings: {
        arrow: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 821,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 426,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      settingClient: {
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        focusOnSelect: true,
        centerMode: true,
        centerPadding: '20px',
        variableWidth: true,
        responsive: [
          {
            breakpoint: 821,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 426,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
      projects: [
        {
          url: 'https://www.google.com/',
          label: 'Google',
        },
        {
          url: 'https://www.google.com/',
          label: 'Google',
        },
        {
          url: 'https://www.google.com/',
          label: 'Google',
        },
        {
          url: 'https://www.google.com/',
          label: 'Google',
        },
      ],
      solutions: [
        {
          title: 'Social Media Management',
          description: "Reusing the same approach in the social media landscape isn't going to drive results. Deep analysis of brand, sentiment, and audience engagement will pave the way to unique strategies that fit your brand like a glove. Your unique brand deserves a unique strategy.",
        },
        {
          title: 'Website Design & Development',
          description: "Stucel is battle-hardened to always bring out the maximum creativity in this sector. Keeping your brand's website design in line with the branding and strategically presenting the right information architecture to your audience. We provide data-driven custom website design and development.",
        },
        {
          title: 'Search Engine Optimization',
          description: "We will give you thorough recommendations on keyword research, optimization of your website—onsite and offsite. The goal is to keep your website and its content relevant, competitive for sales conversion — while also befriending Google. Ideally, our aim is to rank your website on Google's \"page one\". Only 0.78% of all Google users click on a website located on the second page.",
        },
      ],
      reasons: [
        {
          title: 'We understand',
          description: 'We take an integrated, holistic approach to inbound marketing, creating buyer-centric content and website experiences.',
        },
        {
          title: 'We arrange',
          description: 'We combine creative, SEO, social media, paid advertising, websites and your business strategy, to get you inbound results.',
        },
        {
          title: 'We understand',
          description: 'With over our 15+ years, we tried and tested strategy process to shortened your route to success.',
        },
      ],
      testimonies: [
        {
          name: 'Budi Santoso',
          company: 'PT Bank Ina Perdana Tbk',
          testimony: '“Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras mattis consectetur purus sit amet fermentum.”',
        },
        {
          name: 'Budi Santoso',
          company: 'PT Bank Ina Perdana Tbk',
          testimony: '“Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras mattis consectetur purus sit amet fermentum.”',
        },
        {
          name: 'Budi Santoso',
          company: 'PT Bank Ina Perdana Tbk',
          testimony: '“Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras mattis consectetur purus sit amet fermentum.”',
        },
        {
          name: 'Budi Santoso',
          company: 'PT Bank Ina Perdana Tbk',
          testimony: '“Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras mattis consectetur purus sit amet fermentum.”',
        },
      ],
      faq: [
        {
          id: 1,
          question: 'Which social media platform suitable for my business?',
          answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
          id: 2,
          question: 'Which industry is your expertise?',
          answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
          id: 3,
          question: 'Which social media platform suitable for my business?',
          answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
          id: 4,
          question: 'How much is minimum budget for digital marketing campaign?',
          answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
      ],
      formData: {
        slug: null,
        title: null,
        labels: [],
        types: [],
        required: [],
        options: [],
        aggreement: '',
        selectedSalesperson: null,
      },
      formSucceded: false,
      formStore: {},
      lpId: 156,
      lpUuid: 'de8044e2-e707-41a8-9949-448e6f89abd9',
      formId: 27,
      slide: 0,
      required,
      email,
    }
  },
  mounted() {
    this.getFormData()
    Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded().then(async () => {
        this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')
        this.recaptchaToken = token
      })
    },
    getFormData() {
      store.dispatch('menu-form/getFormById', { id: this.formId })
        .then(response => {
          this.formData = response.data.data
          this.formData.labels = JSON.parse(this.formData.labels)
          this.formData.names = JSON.parse(this.formData.names)
          this.formData.options = JSON.parse(this.formData.options)
          this.formData.required = JSON.parse(this.formData.required)
          this.formData.types = JSON.parse(this.formData.types)
          this.formData.length = this.formData.labels.length
        })
    },
    onSubmitForm() {
      this.$refs.formCustomLP.validate().then(success => {
        if (success) {
          const tempFormStore = this.changeRichText()

          store.dispatch('menu-form/inputForm', {
            form_data: tempFormStore,
            aggreement: true,
            input_manually: 0,
            selected_salesperson: this.formData.selectedSalesperson,
            form_id: this.formId,
            landing_page_uuid: this.lpUuid,
          })
            .then(response => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              console.log(error)
              if (error.response !== undefined){
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error has occured',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
              }
            })
        }
      })
    },
    changeRichText() {
      const temp = {}
      this.formData.types.forEach((type, index) => {
        if (type === 'textarea') {
          const value = this.formStore[this.formData.names[index]]
          const formBreak = value.replace(/\r\n|\r|\n/g, '<br>')
          const formSpace = formBreak.replace(/ /g, '&nbsp;')
          temp[this.formData.names[index]] = formSpace
        } else {
          temp[this.formData.names[index]] = this.formStore[this.formData.names[index]]
        }
      })
      return temp
    },
    getProjectImgUrl(index) {
      const images = require.context('./asset/projects/', false, /\.jpg$/)
      return images(`./project_${index}.jpg`)
    },
    getSolutionImgUrl(index) {
      const images = require.context('./asset/solutions/', false, /\.png$/)
      return images(`./solution_${index}.png`)
    },
    getReasonImgUrl(index) {
      const images = require.context('./asset/reasons/', false, /\.png$/)
      return images(`./reason_${index}.png`)
    },
    getClientImgUrl(index) {
      const images = require.context('./asset/clients/', false, /\.png$/)
      return images(`./client_${index}.png`)
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss">
  @import './index.scss';
</style>
